import React, {useContext} from "react";
import {DocumentTitle} from "./DocumentTitle";
import {useIntlMessage} from "../createIntlMessage";
import {AppContextContext} from "../Contexts";

function PageNotFound() {
    const appContext = useContext(AppContextContext);
    const intlMessage = useIntlMessage("page-not-found");

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage("common.page-not-found")}`}>
            <h1>{intlMessage("common.page-not-found")}</h1>
        </DocumentTitle>
    )
}

export default PageNotFound;
