import PageNotFound from "./PageNotFound";
import RegisterAuthenticator from "./RegisterAuthenticator";
import AppInfo from "./AppInfo";

export const routesMap = {
    AppInfo: {
        path: "/",
        component: AppInfo,
        exact: true,
        menuKey: "dashboard"
    },
    RegisterAuthenticator: {
        path: "/register/:invitationId",
        component: RegisterAuthenticator,
        menuKey: "register-authenticator"
    },
    NotFound: {
        component: PageNotFound,
    },
};

// @ts-ignore
export const routes = Object.keys(routesMap).map(key => routesMap[key]);
