import {createIntl, createIntlCache} from "react-intl";
import {appContext} from "./Contexts";
import MessagesUtils from "./sal-ui/MessagesUtils";
import messages_cs_properties from "./translations/messages_cs.properties";
import messages_en_properties from "./translations/messages_en.properties";

const messages: any = {
    'cs': {},
    'en': {},
}

const cache = createIntlCache();

export let intl = createIntl({locale: appContext.language, messages: messages[appContext.language]}, cache)

export function updateLocaleResources(locale: string) {
    intl = createIntl({locale: locale, messages: messages[locale]}, cache)
}

export function loadMessages(): Promise<void> {
    return Promise.all([
        MessagesUtils.loadMessages(messages_cs_properties),
        MessagesUtils.loadMessages(messages_en_properties)
    ]).then((values) => {
        messages.cs = values[0];
        messages.en = values[1];

        intl = createIntl({locale: appContext.language, messages: messages[appContext.language]}, cache)

        return;
    });
}
