class Principal {
    constructor(
        public id?: string,
        public role?: string,
        public username?: string,
        public email?: string) {
        //
    }

    public static fromPOJO(data: any) {
        const user = new Principal();

        user.id = data.id;
        user.role = data.role;
        user.username = data.username;
        user.email = data.email;

        return user;
    }
}

export default Principal;
