import Axios from "axios";
import Authenticator from "../domain/Authenticator";

class AuthenticatorService {

    public findByInvitationId(invitationId: string): Promise<Authenticator> {
        return Axios.get(`/authenticators/invitations/${invitationId}`)
            .then(value => Authenticator.fromPOJO(value.data))
    }

    public registerYubiKeyAuthenticator(id: string, yubiKeyOtp: string): Promise<void> {
        return Axios.put(`/authenticators/${id}/registration`, {yubiKeyOtp});
    }

}

export default AuthenticatorService;
