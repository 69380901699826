import React from "react";
import {AppContext} from "./AppContext";
import AuthenticatorService from "./service/AuthenticatorService";
import PreferenceService from "./service/PreferenceService";

export const appContext = new AppContext();
export const preferenceService = new PreferenceService();

const authenticatorService = new AuthenticatorService();

export const AppContextContext = React.createContext(appContext);
export const AuthenticatorServiceContext = React.createContext(authenticatorService);
export const PreferenceServiceContext = React.createContext(preferenceService);
