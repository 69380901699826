import React from 'react';
import {Helmet} from 'react-helmet-async';

interface Props {
    children?: React.ReactNode;
    title: string;
}

export const DocumentTitle = ({children, title}: Props) => (
    <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {children}
    </>
);
