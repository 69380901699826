import {ConfigProvider} from "antd";
import cs_CZ from "antd/lib/locale-provider/cs_CZ";
import en_US from "antd/lib/locale-provider/en_US";
import {useObserver} from "mobx-react-lite";
import React, {useContext} from 'react';
import {RawIntlProvider} from "react-intl";
import './App.css';
import HomePage from "./components/HomePage";
import {AppContextContext} from "./Contexts";
import {intl} from "./Intl";

function App() {
    const appContext = useContext(AppContextContext);

    return useObserver(() => (
        <RawIntlProvider value={intl} key={appContext.language}>
            <ConfigProvider locale={(appContext.language === 'cs') ? cs_CZ : en_US}>
                <div className="App">
                    <HomePage/>
                </div>
            </ConfigProvider>
        </RawIntlProvider>
    ));
}

export default App;
