import PreferenceNamespace from "./PreferenceNamespace";

class PreferenceService {

    private static readonly APP_PREFIX = "User.";

    public getItem(namespace: PreferenceNamespace): string | null {
        return localStorage.getItem(PreferenceService.createKey(namespace));
    }

    public setItem(namespace: PreferenceNamespace, value: string): void {
        localStorage.setItem(PreferenceService.createKey(namespace), value);
    }

    public setIndexedItem(namespace: PreferenceNamespace, index: string, value: string): void {
        const serializedData = localStorage.getItem(PreferenceService.createKey(namespace));

        const data = (serializedData != null) ? JSON.parse(serializedData) : {};

        data[index] = value;

        localStorage.setItem(PreferenceService.createKey(namespace), JSON.stringify(data));
    }

    public getIndexedItem(namespace: PreferenceNamespace, index: string): string | null {
        const serializedData = localStorage.getItem(PreferenceService.createKey(namespace,));

        if (serializedData == null) {
            return null;
        }

        const data = JSON.parse(serializedData);

        return data[index];
    }

    public removeItem(namespace: PreferenceNamespace): void {
        localStorage.removeItem(PreferenceService.createKey(namespace));
    }

    public removeIndexedItem(namespace: PreferenceNamespace, index: string): void {
        const serializedData = localStorage.getItem(PreferenceService.createKey(namespace));

        const data = (serializedData != null) ? JSON.parse(serializedData) : {};

        delete data[index];

        localStorage.setItem(PreferenceService.createKey(namespace), JSON.stringify(data));
    }

    private static createKey(namespace: PreferenceNamespace) {
        return `${PreferenceService.APP_PREFIX}${namespace}`;
    }

}

export default PreferenceService;
