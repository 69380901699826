import {Layout} from "antd";
import React, {useContext} from "react";
import {renderRoutes} from "react-router-config";
import {AppContextContext} from "../Contexts";
import "./HomePage.css";
import {routes} from "./Routes";
import SelectLang from "./SelectLang";
import {Link} from "react-router-dom";

const {Header, Content} = Layout;

function HomePage() {
    const appContext = useContext(AppContextContext);

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Header className={"home-page__header"}>
                <div className={"home-page__logo"}>
                    <Link to={"/"}>
                        {appContext.config?.logo !== "" ?
                            <img src={appContext.config?.logo} alt={appContext.config?.fqdn}/> :
                            <img src="/logo.svg" alt={appContext.config?.fqdn}/>
                        }
                    </Link>
                </div>

                <div className={"home-page__toolbar"}/>

                <SelectLang/>
            </Header>
            <Layout>
                <Content style={{margin: '16px'}}>
                    <div style={{padding: 24, minHeight: 300}}>
                        {renderRoutes(routes)}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );

}

export default HomePage;
