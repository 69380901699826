import React, {useContext} from "react";
import {DocumentTitle} from "./DocumentTitle";
import {useIntlMessage} from "../createIntlMessage";
import {AppContextContext} from "../Contexts";

function AppInfo() {
    const appContext = useContext(AppContextContext);
    const intlMessage = useIntlMessage("app-info");

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage("title")}`}>
            <>
                <h1>{intlMessage("header")}</h1>
                <p>{intlMessage("text")}</p>
            </>
        </DocumentTitle>
    )
}

export default AppInfo;
