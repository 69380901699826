enum PreferenceNamespace {
    SettingsLanguage = "Settings.Language",
    TablePage = "Table.Page",
    TablePageSize = "Table.PageSize",
    TableOrderBy = "Table.OrderBy",
    TableFilter = "Table.Filter",
    TableSearchFormValues = "Table.SearchFormValues",
}

export default PreferenceNamespace;
