import Axios from "axios";

class MessagesUtils {

    public static loadMessages(url: string): Promise<any> {
        return Axios.get(url, {baseURL: ""})
            .then((value) => {
                const text: string = value.data as string;

                const lines = text.split(/\r\n|\n/);

                const messages: any = {};

                for (const line of lines) {
                    const equalsIndex = line.indexOf("=");

                    if (line.length < 3) {
                        continue;
                    }

                    const key = line.substring(0, equalsIndex);
                    const message = MessagesUtils.ascii2native(line.substring(equalsIndex + 1)).replace(/\\:/g, ":");

                    messages[key] = (message) ? message : " ";
                }

                return Promise.resolve(messages);
            });
    }

    private static ascii2native(str: string) {
        return unescape((str + '').replace(/\\(?=u[\da-z]{4})/gi, '%'));
    }

}

export default MessagesUtils;
