import {Button} from 'antd';
import * as React from "react";
import {useContext} from "react";
import {AppContextContext, PreferenceServiceContext} from "../Contexts";
import {updateLocaleResources} from "../Intl";
import "./SelectLang.css";
import PreferenceNamespace from "../service/PreferenceNamespace";

function SelectLang() {
    const appContext = useContext(AppContextContext);
    const preferenceService = useContext(PreferenceServiceContext);

    return (
        <div className={"select-lang"}>
            <Button className={"select-lang__language " + (appContext.language === 'cs' ? "select-lang__language--selected" : "")} onClick={() => changeLang("cs")}>CS</Button>
            <Button className={"select-lang__language " + (appContext.language === 'en' ? "select-lang__language--selected" : "")} onClick={() => changeLang("en")}>EN</Button>
        </div>
    );

    function changeLang(locale: string) {
        updateLocaleResources(locale);

        appContext.language = locale;

        preferenceService.setItem(PreferenceNamespace.SettingsLanguage, locale);
    }
}

export default SelectLang;
