import {IntlMessageFormatter} from "../createIntlMessage";
import Principal from "./Principal";

class Authenticator {
    constructor(
        public id?: string,
        public name?: string,
        public createdAt?: Date,
        public principals?: Principal[],
        public type?: string,
        public state?: string,
        public friendlyInvitationId?: string,
        public invitationExpiresAt?: Date,
        public phoneNumber?: string,
        public deviceModel?: string,
        public osVersion?: string,
        public publicKeyFingerprint?: string,
        public registerUrl?: string,
        public publicId?: string) {
        //
    }

    public static fromPOJO(data: any) {
        const authenticator = new Authenticator();

        authenticator.id = data.id;
        authenticator.name = data.name;
        authenticator.createdAt = (data.createdAt) ? new Date(data.createdAt) : undefined;
        authenticator.type = data.type;
        authenticator.state = data.state;
        authenticator.friendlyInvitationId = data.friendlyInvitationId;
        authenticator.invitationExpiresAt = (data.invitationExpiresAt) ? new Date(data.invitationExpiresAt) : undefined;
        authenticator.phoneNumber = data.phoneNumber;
        authenticator.deviceModel = data.deviceModel;
        authenticator.osVersion = data.osVersion;
        authenticator.publicKeyFingerprint = data.publicKeyFingerprint;
        authenticator.registerUrl = data.registerUrl;
        authenticator.publicId = data.publicId;

        if (data.principals) {
            authenticator.principals = data.principals.map((principal: any) => Principal.fromPOJO(principal))
        }

        return authenticator;
    }

    public get deviceInfo() {
        if (this.type === 'MOBILE') {
            return (this.state === 'REGISTERED') ? this.phoneNumber + " - " + this.deviceModel + " (" + this.osVersion + ")" : this.phoneNumber;
        } else if (this.type === 'YUBIKEY') {
            return (this.state === 'REGISTERED') ? "PublicID: " + this.publicId : "";
        } else {
            return "";
        }
    }

    public static formatState(intlMessage: IntlMessageFormatter, state?: string) {
        switch (state) {
            case "NONE":
                return intlMessage("authenticator-state.NONE");
            case "INVITED":
                return intlMessage("authenticator-state.INVITED");
            case "REGISTERED":
                return intlMessage("authenticator-state.REGISTERED");
            default:
                return "?";
        }
    }

    public static formatType(intlMessage: IntlMessageFormatter, type?: string) {
        switch (type) {
            case "MOBILE":
                return intlMessage("authenticator-type.MOBILE");
            case "YUBIKEY":
                return intlMessage("authenticator-type.YUBIKEY");
            default:
                return "?";
        }
    }

}

export default Authenticator;
